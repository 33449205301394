import * as React from 'react';
import {
    AppBar,
    Logout,
    UserMenu,
    useTranslate,
    useGetIdentity,
    RefreshIconButton,
    TitlePortal,
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Theme,
    Avatar,
    Button,
    Menu,
    Stack,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Logo from './Logo';
import { deepOrange } from '@mui/material/colors';
import { useEffect } from 'react';
import { useStore } from 'react-admin';
import CustomOnlineStore from '../resources/controls/CustomOnlineStore';

const ConfigurationMenu = React.forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItem
            component={Link}
            // @ts-ignore
            ref={ref}
            {...props}
            to="/configuration"
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>{translate('pos.configuration')}</ListItemText>
        </MenuItem>
    );
});

// const CustomUserMenu = () => (
//     <UserMenu>
//         <ConfigurationMenu />
//         <Logout />
//     </UserMenu>
// );

const SimpleUserMenu = () => {
    const { identity } = useGetIdentity();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        return;
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Stack direction="row" spacing={1}>
                <RefreshIconButton />
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick} //handleClick
                >
                    <Avatar
                        sx={{
                            bgcolor: deepOrange[500],
                            marginRight: 1,
                            width: 30,
                            height: 30,
                            fontSize: 14,
                        }}
                    >
                        {identity?.initials}
                    </Avatar>
                    {identity?.fullName}
                </Button>
                <Logout />
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <ConfigurationMenu />
                <Logout />
            </Menu>
        </>
    );
};

// const resource = 'CustomFilterList';
// const [pudc, setFilterListSettings] = useStore(`preferences.user.datagrid.columns`, "");

// const checkLocalStorage: any = () => {

// }

const CustomAppBar = (props: any) => {
    //console.log('CustomAppBar');

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );
    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<SimpleUserMenu />}
            toolbar={<span />}
        >
            {/* <TitlePortal title='INFRA -admin'  /> */}
            {/*
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            */}

            {isLargeEnough && <Logo />}
            {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
            <CustomOnlineStore />
        </AppBar>
    );
};

export default CustomAppBar;
