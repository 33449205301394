import * as React from 'react';
import {
    Identifier,
    RaRecord,
    List,
    Datagrid,
    TextField,
    SearchInput,
    useTranslate,
    useEditContext,
    ListProps,
    DateField,
    FunctionField,
    BooleanInput,
    useRecordContext,
    required,
} from 'react-admin';
import CustomEmpty from './CustomEmpty';
import Diversity1 from '@mui/icons-material/Diversity1';
import { Box, Chip, Stack } from '@mui/material';
import CustomSelectInput from './CustomSelectInput';
import { FilterSharp } from '@mui/icons-material';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

interface Props extends ListProps {
    bulkActionButtons?:
        | false
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    storeKey?: string;
    params?: any;
}

const UserGroupForm = (props: Props) => {
    const record = useRecordContext();
    const translate = useTranslate();
    console.log('UserGroupForm', props);
    return (
        <RowForm
            defaultValues={{
                guid: record.guid,
                user_guid: record.user_guid,
                group_guid: record.group_guid,
            }}
        >
            {!props.hideFields?.includes('name') && (
                <FunctionField
                    label={translate('resources.group.name', 2)}
                    render={record => {
                        return (
                            <Stack direction="row" spacing={2}>
                                <Box>{record.group.name}</Box>
                                {!props.hideFields?.includes('group_status') &&
                                    record.group.status !== 0 && (
                                        <Chip
                                            label={translate(
                                                'resources.usergroup.status.' +
                                                    record.group.status
                                            )}
                                            size="small"
                                            color="error"
                                        />
                                    )}
                                {record.group.public.archived && (
                                    <Chip
                                        label={translate(
                                            'resources.group.fields.archived'
                                        )}
                                        size="small"
                                        color="error"
                                    />
                                )}
                            </Stack>
                        );
                    }}
                />
            )}
            {!props.hideFields?.includes('join') && (
                <CustomSelectInput
                    source="status"
                    url="extralist/user/statuses"
                    label="Tila"
                    validate={[required()]}
                />
            )}
            {!props.hideFields?.includes('join') && (
                <DateField
                    source="author.created"
                    locales={'fi'}
                    sortByOrder="DESC"
                    label={translate(
                        'resources.student.fields.school.registered'
                    )}
                />
            )}
        </RowForm>
    );
};

const CustomUserGroupList = (props: Props) => {
    console.log('CustomUserGroupList', props);
    const translate = useTranslate();
    const cRowClick = (id: Identifier, resource: string, record: RaRecord) => {
        //console.log('cRowClick', id, resource, record);
        return '/group/' + record.group.guid;
    };
    const parent = useEditContext();
    // console.log('parent',parent);
    // const useRecord = useRecordContext();
    // console.log('useRecord',useRecord);

    //console.log('expanded',props);

    var filter = {};

    if (props.filter) filter = props.filter;

    if (props.customFilters?.includes('parent_guid'))
        filter['parent_guid'] = parent.record.group_guid
            ? parent.record.group_guid
            : parent.record.guid;

    if (props.customFilters?.includes('user_guid'))
        filter['user_guid'] = parent.record.user_guid
            ? parent.record.user_guid
            : props.params['user_guid'];

    let filters = [];
    if (props.showExtra?.includes('search'))
        filters.push(<SearchInput source="q" size="small" alwaysOn />);
    if (props.showExtra?.includes('onlyactive'))
        filters.push(
            <BooleanInput
                source="onlyactive"
                label="resources.usergroup.filters.onlyactive"
                alwaysOn={true}
            />
        );

    return (
        <List
            sx={{ margin: '20px 10px 0px 70px' }}
            {...props}
            actions={false}
            storeKey={false}
            filter={filter}
            filters={filters.length > 0 ? filters : undefined}
            empty={<CustomEmpty icon={Diversity1} />}
        >
            {/* <Datagrid bulkActionButtons={props.bulkActionButtons} expand={<GroupEdit />} expandSingle={true} rowClick="expand" className='cgl-dg'>
                {!props.hideFields?.includes("name") && <TextField source="group.name" label={translate('resources.group.fields.name')} sortable={props.sortable} />}
            </Datagrid> */}
            <EditableDatagrid
                bulkActionButtons={props.bulkActionButtons}
                rowClick={cRowClick}
                className="cgl-dg"
                editForm={UserGroupForm(props)}
                createForm={null}
                noDelete
            >
                {!props.hideFields?.includes('name') && (
                    <FunctionField
                        label={translate('resources.group.name', 2)}
                        sortable={props.sortable}
                        render={record => {
                            return (
                                <Stack direction="row" spacing={2}>
                                    <Box>{record.group.name}</Box>
                                    {!props.hideFields?.includes(
                                        'group_status'
                                    ) &&
                                        record.group.status !== 0 && (
                                            <Chip
                                                label={translate(
                                                    'resources.usergroup.status.' +
                                                        record.group.status
                                                )}
                                                size="small"
                                                color="error"
                                            />
                                        )}
                                    {!props.hideFields?.includes('archived') &&
                                        record.group.public.archived && (
                                            <Chip
                                                label={translate(
                                                    'resources.group.fields.archived'
                                                )}
                                                size="small"
                                                color="error"
                                            />
                                        )}
                                </Stack>
                            );
                        }}
                    />
                )}
                {!props.hideFields?.includes('status') && (
                    <CustomSelectInput
                        source="status"
                        label={translate('resources.user.fields.group.status')}
                        url="extralist/student/statuses"
                        showAsText={true}
                    />
                )}
                {!props.hideFields?.includes('join') && (
                    <DateField
                        source="author.created"
                        locales={'fi'}
                        sortByOrder="DESC"
                        label={translate(
                            'resources.student.fields.school.registered'
                        )}
                    />
                )}
            </EditableDatagrid>
        </List>
    );
};

export default CustomUserGroupList;
