import * as React from 'react';
import {
    useRecordContext,
    useDataProvider,
    useResourceContext,
} from 'react-admin';
import { useQuery } from '@tanstack/react-query';
import find from 'lodash/find';

interface Props {
    source?: string;
    resource?: string;
    label?: string;
    sortable?: boolean;
    school_guid?: string;
}

const SubjectListField = ({
    source = 'group',
    label = '',
    sortable = false,
    resource,
    school_guid,
}: Props) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const originalResource = useResourceContext();
    if (!resource) resource = originalResource;

    const url = 'extralist/teacher/roles';
    let params = '';

    const { data, error, isPending, refetch } = useQuery({
        queryKey: [url, 'getAny', params],
        queryFn: () => dataProvider.getAny(url, params),
    });

    if (!data) return null;

    let foundSubroles = data.data[0];
    let teacherSubroles = [];

    switch (resource) {
        case 'teacher':
            if (school_guid)
                teacherSubroles =
                    record?.schools?.find(s => s.guid === school_guid)?.public
                        ?.subject ?? [];
            else
                teacherSubroles =
                    record?.schools?.[0]?.public?.subject ??
                    record?.public?.subject ??
                    [];
            break;
        case 'usergroup':
            teacherSubroles = record?.public?.subject ?? [];
            break;
        default:
            break;
    }

    let subroles = '';
    if (foundSubroles && teacherSubroles.length > 0) {
        subroles = teacherSubroles
            .map(x => {
                let subrole = find(foundSubroles.subject, {
                    guid: x.toUpperCase(),
                });
                return subrole ? subrole.abbreviation : '-';
            })
            .join(', ');
    }

    return <div>{subroles}</div>;
};

export default SubjectListField;
