import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SearchInput,
    useTranslate,
    useEditContext,
    ListProps,
    BooleanInput,
    FunctionField,
} from 'react-admin';
import CustomEmpty from './CustomEmpty';
import { Box, Chip, Stack } from '@mui/material';
import CustomSelectInput from './CustomSelectInput';
interface Props extends ListProps {
    bulkActionButtons?:
        | false
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | undefined;
    sortable?: boolean;
    showExtra?: any;
    hideFields?: any;
    customFilters?: any;
    refreshKey?: string;
    storeKey?: string;
}

const CustomGroupList = (props: Props) => {
    const {
        sortable = true,
        showExtra = [],
        hideFields = [],
        customFilters = [],
        refreshKey = 'refreshAll',
        storeKey = 'customGroupList',
        // ...other props
    } = props;

    const translate = useTranslate();
    const parent = useEditContext();
    var filter = {};

    if (props.filter) filter = props.filter;

    if (props.customFilters?.includes('parent_guid'))
        filter['parent_guid'] = parent.record.guid;

    if (props.customFilters?.includes('user_guid'))
        filter['user_guid'] = parent.record.guid;

    let filters = [];
    if (props.showExtra?.includes('search'))
        filters.push(<SearchInput source="q" size="small" alwaysOn />);
    if (props.showExtra?.includes('onlyactive'))
        filters.push(
            <BooleanInput
                source="onlyactive"
                label="resources.usergroup.filters.onlyactive"
                alwaysOn={true}
            />
        );

    return (
        <List
            {...props}
            actions={false}
            filter={filter}
            filters={filters.length > 0 ? filters : undefined}
            empty={<CustomEmpty />}
        >
            <Datagrid
                bulkActionButtons={props.bulkActionButtons}
                rowClick="edit"
                className="cgl-dg"
            >
                {!props.hideFields?.includes('name') && (
                    <FunctionField
                        label={translate('resources.group.name', 2)}
                        sortable={props.sortable}
                        render={record => {
                            return (
                                <Stack direction="row" spacing={2}>
                                    <Box>{record.name}</Box>
                                    {!props.hideFields?.includes(
                                        'group_status'
                                    ) &&
                                        record.status !== 0 && (
                                            <Chip
                                                label={translate(
                                                    'resources.usergroup.status.' +
                                                        record.status
                                                )}
                                                size="small"
                                                color="error"
                                            />
                                        )}
                                    {!props.hideFields?.includes('archived') &&
                                        record.public.archived && (
                                            <Chip
                                                label={translate(
                                                    'resources.group.fields.archived'
                                                )}
                                                size="small"
                                                color="error"
                                            />
                                        )}
                                </Stack>
                            );
                        }}
                    />
                )}
            </Datagrid>
        </List>
    );
};

export default CustomGroupList;
