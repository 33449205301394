import { jwtDecode } from 'jwt-decode';

const tokenManager = () => {
    let isRefreshing = null;
    let isFetchingProfile = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = '/api/auth/refresh_token';
    let inMemoryProfile;
    let refreshTimeOutId;
    let refreshExecutionTime;
    let refreshProcentBeforeExpiry = 20;

    const getPermissions = async () => {
        await waitForTokenRefresh();
        await waitForProfileFetch();
        if (!inMemoryProfile?.rbac) {
            return Promise.reject('No permissions found');
        }
        return Promise.resolve(inMemoryProfile.rbac);
    };

    const getIdentity = async () => {
        await waitForTokenRefresh();
        await waitForProfileFetch();
        if (!inMemoryProfile) {
            console.log('No identity found');
            return Promise.resolve();
            /*return Promise.reject('No identity found');*/
        }
        return Promise.resolve(inMemoryProfile);
    };

    const isValidAccessToken = async () => {
        await getIdentity();
        const token = getToken();
        if (!token) return Promise.reject('login.no_token');
        /*
        if (inMemoryProfile.nonce !== localStorage.getItem('nonce'))
            return Promise.reject('login.nonce_mismatch');
        */
        return Promise.resolve();
    };

    const setLogoutEventName = name => (logoutEventName = name);
    const setRefreshTokenEndpoint = endpoint => (refreshEndpoint = endpoint);

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const refreshToken = delay => {
        refreshExecutionTime = Date.now() + delay * 1000;
        refreshTimeOutId = window.setTimeout(getRefreshedToken, delay * 1000);
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = async () => {
        let expiresIn =
            (Number(localStorage.getItem('tokenExpiry')) - Date.now()) / 1000;
        let refreshIn =
            expiresIn - (expiresIn / 100) * refreshProcentBeforeExpiry;

        //in case user is refreshing the page
        if (!refreshTimeOutId && localStorage.getItem('tokenExpiry')) {
            refreshToken(refreshIn);
        }

        let realRefreshIn = (refreshExecutionTime - Date.now()) / 1000;
        if (refreshTimeOutId) {
            console.log(
                `token expires in: ${expiresIn.toFixed(
                    2
                )} s. realRefresh in: ${realRefreshIn.toFixed(
                    2
                )} s. refresh in : ${refreshIn.toFixed(2)} s.`
            );
        }

        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    };

    const waitForProfileFetch = async () => {
        if (inMemoryProfile) return;
        if (isFetchingProfile) {
            return isFetchingProfile.then(() => {
                isFetchingProfile = null;
                return;
            });
        }
        return getProfile();
    };

    const getProfile = async () => {
        let token = getToken();
        if (!token) return;

        isFetchingProfile = fetch('/api/auth/profile', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then(response => {
                if (response.status !== 200) {
                    let error = new Error(response.statusText);
                    error.status = response.status;
                    console.log(
                        'Error fetching profile: ' + response.statusText
                    );
                    throw error;
                }
                return response.json();
            })
            .then(profile => {
                inMemoryProfile = profile;
                console.log('Profile fetched');
                return;
            });
        return isFetchingProfile;
    };

    // The method make a call to the refresh-token endpoint
    const getRefreshedToken = () => {
        const token = getToken();
        const request = new Request(refreshEndpoint, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            }),
            credentials: 'include',
        });

        isRefreshing = fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    ereaseToken();
                    global.console.log('Token renewal failure');
                    return { token: null };
                }
                return response.json();
            })
            .then(({ token, tokenExpiry }) => {
                if (token) {
                    console.log('Token refres: ' + tokenExpiry + ' s.');
                    setToken(token, tokenExpiry);
                    return true;
                }
                console.log('Ei tänne');
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };

    const getToken = () => localStorage.getItem('token');
    const setToken = async (token, tokenExpiryInSec) => {
        window.localStorage.setItem('token', token);
        inMemoryProfile = null;
        const tokenExpiry = Date.now() + tokenExpiryInSec * 1000;
        const delay = tokenExpiryInSec - (tokenExpiryInSec / 100) * 20;
        window.localStorage.setItem('tokenExpiry', tokenExpiry);
        if (!localStorage.getItem('nonce'))
            window.localStorage.setItem('nonce', jwtDecode(token).nonce);
        refreshToken(delay);
        return;
    };

    const ereaseToken = async () => {
        inMemoryProfile = null;
        abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('nonce');
        window.localStorage.removeItem('state');
        window.localStorage.removeItem('tokenExpiry');
        return true;
    };

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', event => {
        if (event.key === logoutEventName) {
            inMemoryProfile = null;
        }
    });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
        getPermissions,
        getIdentity,
        isValidAccessToken,
    };
};

export default tokenManager();
