import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import {
    useRecordContext,
    useResourceContext,
    useTranslate,
    useNotify,
    useRefresh,
} from 'react-admin';
import { Box, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

interface SessionBadgeProps {
    children: ReactNode;
    options?: { showTTL?: boolean; showSessionDelete?: boolean };
    source?: string;
    sortByOrder?: string;
}

const formatTTL = (ttl: number) => {
    const days = Math.floor(ttl / (24 * 3600));
    const hours = Math.floor((ttl % (24 * 3600)) / 3600);
    const minutes = Math.floor((ttl % 3600) / 60);
    const seconds = ttl % 60;

    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    parts.push(`${seconds}s`);

    return parts.join(' ');
};

const SessionBadge: FC<SessionBadgeProps> = ({
    children,
    options = { showTTL: false, showSessionDelete: false },
}) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const apps = [
        { name: 'teacher', guid: '0e274549-7a89-4313-8720-0f4588a51f98' },
        { name: 'student', guid: 'a7faf0f2-3cfc-4f62-9a77-aa75134ac5a4' },
        { name: 'pupil', guid: '5b846602-4428-4c2d-b12a-d2b56dd722b7' },
    ];
    const currentAppGuid = apps.find(a => a.name === resource)?.guid;
    const currentAppTTL = record?.application?.find(
        a => a.guid === currentAppGuid
    )?.ttl;

    if ((Number(currentAppTTL) || 0) <= 0) return children;

    return (
        <Stack direction="row" spacing={1}>
            {children}
            <StyledBadge
                overlap="rectangular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                variant="dot"
            >
                &nbsp;
            </StyledBadge>
            {options.showTTL && (
                <Box
                    sx={{
                        fontSize: '0.9rem',
                        color: 'text.secondary',
                        paddingLeft: '1.5rem',
                    }}
                >
                    {translate('resources.user.session.remaining')}:{' '}
                    {formatTTL(record.application[0].ttl)}
                </Box>
            )}
            {options.showSessionDelete && (
                <IconButton
                    onClick={() => {
                        let action = 'REMOVE_SESSION';
                        let data = {
                            ...record,
                            ...{ action },
                        };
                        fetch(
                            `${process.env.REACT_APP_INFRA_API}/${resource}/${record.guid}`,
                            {
                                method: 'PUT',
                                headers: {
                                    Accept: 'application/json',
                                    'Content-Type': 'application/json',
                                    school: localStorage.getItem('school'),
                                    authorization:
                                        'Bearer ' +
                                        localStorage.getItem('token'),
                                },
                                body: JSON.stringify(data),
                            }
                        ).then(ret => {
                            if (ret.status !== 200) {
                                notify(
                                    translate('notifications.ACTION_ERROR'),
                                    { type: 'error' }
                                );
                            } else {
                                /*All good*/
                                notify(
                                    translate('notifications.SESSION_REMOVED'),
                                    { type: 'success' }
                                );
                                refresh();
                            }
                        });
                    }}
                    size="small"
                    title={translate('resources.user.actions.remove_session')}
                    sx={{ padding: 0 }}
                >
                    <DeleteIcon style={{ color: red[500] }} />
                </IconButton>
            )}
        </Stack>
    );
};

export default SessionBadge;
