import * as React from 'react';
import {
    Edit,
    NumberInput,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    DateInput,
    TextField,
    Labeled,
    FunctionField,
    ReferenceField,
    DateField,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
    useRefresh,
} from 'react-admin';
import { Grid, Box, Typography, Stack } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import StatisticsTile from '../../components/StatisticsTile';

import {
    CustomRadioButtonGroupInput,
    CustomBulkDeleteButton,
    CustomUserLicenseList,
    CustomLogEventList,
    CustomCheckboxGroupInput,
    CustomSubscriptionList,
    CustomUserList,
    CustomProductList,
    CustomAddSubscriptionButton,
    CustomGroupList,
} from '../controls';

import user from '../user';

import {
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    History,
    Person,
    ReceiptLong,
    TransferWithinAStation,
    Diversity1,
    Subscriptions,
    School,
    Face,
    Face5,
} from '@mui/icons-material';
import { group } from 'console';

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const SchoolEdit = () => {
    //const refresh = useRefresh();

    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />}>
                {/* Tähän perustiedot */}
                <FormTab
                    label="resources.school.tabs.details"
                    icon={<Article />}
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextInput
                                resource="school"
                                source="name"
                                validate={[required()]}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <CustomCheckboxGroupInput
                                        resource="school"
                                        source="public.categories"
                                        url="extralist/product/categorygroups"
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <CustomCheckboxGroupInput
                                        resource="school"
                                        source="public.hidepages"
                                        url="extralist/school/hidepages"
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <CustomCheckboxGroupInput
                                        resource="school"
                                        source="public.features"
                                        url="extralist/school/features"
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.school_email"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        {/* <Grid item xs={12} md={12}> */}

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <TextInput
                                        resource="school"
                                        source="public.headmaster"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.headmaster_phone"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.headmaster_cellphone"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.headmaster_email"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <TextInput
                                        resource="school"
                                        source="public.number_of_teachers"
                                        validate={[maxLength(3)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.number_of_pupils"
                                        validate={[maxLength(4)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.grade_min"
                                        validate={[maxLength(4)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.grade_max"
                                        validate={[maxLength(4)]}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <TextInput
                                        resource="school"
                                        source="public.phone_1"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.phone_2"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.office_phone"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <TextInput
                                        resource="school"
                                        source="public.address"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.zip_code"
                                        validate={[maxLength(6)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.city"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <TextInput
                                        resource="school"
                                        source="public.visit_address"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.visit_zip_code"
                                        validate={[maxLength(5)]}
                                        fullWidth
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.visit_city"
                                        validate={[maxLength(50)]}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: '0.5em' }}>
                                    <TextInput
                                        resource="school"
                                        source="public.identifier"
                                        fullWidth
                                        readOnly
                                    />
                                    <TextInput
                                        resource="school"
                                        source="public.kvpwd"
                                        fullWidth
                                        readOnly
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        {/* </Grid> */}

                        <Grid item xs={12} md={12}>
                            <Stack direction="row" spacing={4}>
                                <Box>
                                    <Labeled label="resources.pupil.fields.author.creator">
                                        <>
                                            <ReferenceField
                                                reference="user"
                                                source="author.creator"
                                            >
                                                <FunctionField
                                                    render={record =>
                                                        `${record.public.firstname} ${record.public.lastname}`
                                                    }
                                                />
                                            </ReferenceField>
                                            <DateField
                                                resource="user"
                                                source="author.created"
                                                showTime
                                                locales={'fi'}
                                            />
                                        </>
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled label="resources.pupil.fields.author.modifier">
                                        <>
                                            <ReferenceField
                                                reference="user"
                                                source="author.modifier"
                                            >
                                                <FunctionField
                                                    render={record =>
                                                        `${record.public.firstname} ${record.public.lastname}`
                                                    }
                                                />
                                            </ReferenceField>
                                            <DateField
                                                resource="user"
                                                source="author.modified"
                                                showTime
                                                locales={'fi'}
                                            />
                                        </>
                                    </Labeled>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </FormTab>
                {/* 
                    - Tähän tilauksiin tiedot. 
                    - Lista oppilaslisensseistä
                    - Lista koululisensseistä
                    - Mahdollisuus lisätä lisenssi
                */}
                {
                    <FormTab
                        label="resources.school.tabs.subscription"
                        icon={<Subscriptions />}
                        path="tilaukset"
                    >
                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5" paragraph>
                                    Lisää tilaus
                                </Typography>
                                <CustomProductList
                                    resource="product"
                                    disableSyncWithLocation
                                    customFilters={[
                                        'use_query_min_length',
                                        'use_v2',
                                    ]}
                                    showExtra={['search', 'info']}
                                    sort={{ field: 'name', order: 'ASC' }}
                                    bulkActionButtons={
                                        <CustomAddSubscriptionButton />
                                    }
                                >
                                    <span />
                                </CustomProductList>
                            </Grid>
                        </Grid>

                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5" paragraph>
                                    Oppilaslisenssit
                                </Typography>
                                <CustomSubscriptionList
                                    resource="subscription"
                                    disableSyncWithLocation
                                    storeKey="school.pupil.subscription.listParams"
                                    customFilters={['subscriber_code', 'pupil']}
                                    showExtra={['search']}
                                    hideFields={['orderlink']}
                                    sort={{ field: 'product', order: 'ASC' }}
                                    bulkActionButtons={false}
                                    filterDefaultValues={{ isvalid: 'current' }}
                                >
                                    <span />
                                </CustomSubscriptionList>
                            </Grid>
                        </Grid>

                        <Grid container width={{ xs: '100%' }} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h5" paragraph>
                                    Koululisenssit
                                </Typography>
                                <CustomSubscriptionList
                                    resource="subscription"
                                    disableSyncWithLocation
                                    storeKey="school.teacher.subscription.listParams"
                                    customFilters={[
                                        'subscriber_code',
                                        'teacher',
                                    ]}
                                    showExtra={['search']}
                                    hideFields={[
                                        'used_license_count',
                                        'orderlink',
                                    ]}
                                    sort={{ field: 'product', order: 'ASC' }}
                                    bulkActionButtons={false}
                                    filterDefaultValues={{ isvalid: 'current' }}
                                >
                                    <span />
                                </CustomSubscriptionList>
                            </Grid>
                        </Grid>
                    </FormTab>
                }
                {/* 
                    - Tähän ryhmät.
                    - Sivutettu sortattava listaus.
                    - Haku???
                */}
                <FormTab
                    label="resources.school.tabs.groups"
                    icon={<Diversity1 />}
                    path="ryhmat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomGroupList
                                disableSyncWithLocation
                                storeKey="schoolGroupList"
                                resource="group"
                                filter={{}}
                                customFilters={['parent_guid']}
                                showExtra={['search', 'onlyactive']}
                                hideFields={['']}
                                sort={{ field: 'name', order: 'ASC' }}
                                bulkActionButtons={false}
                            >
                                <span />
                            </CustomGroupList>
                        </Grid>
                    </Grid>
                </FormTab>
                {/* 
                    - Tähän opettajat.
                    - Sivutettu sortattava listaus.
                    - Haku???
                */}
                <FormTab
                    label="resources.school.tabs.teachers"
                    icon={<School />}
                    path="opettajat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserList
                                disableSyncWithLocation
                                storeKey="schoolTeacherList"
                                resource="teacher"
                                filter={{
                                    roletype: '0,1',
                                    status: '0,2,3',
                                    usergroup_status: '0,2,3',
                                }}
                                customFilters={['group']}
                                showExtra={['search']}
                                hideFields={[
                                    'email',
                                    'code',
                                    'middlename',
                                    'loginstudent',
                                ]}
                                sort={{
                                    field: 'public.lastname',
                                    order: 'ASC',
                                }}
                                bulkActionButtons={false}
                                emptyIcon={user.iconTeacher}
                            >
                                <span />
                            </CustomUserList>
                        </Grid>
                    </Grid>
                    {/* <CustomUserList resource='teacher' disableSyncWithLocation customFilters={['group']} showExtra={['search']} hideFields={[]} sort={{ field: 'public.lastname', order: 'ASC' }} bulkActionButtons={false}><span /></CustomUserList> */}
                </FormTab>

                {/* 
                    - Tähän oppilaat.
                    - Sivutettu sortattava listaus.
                    - Haku???
                */}
                <FormTab
                    label="resources.school.tabs.pupils"
                    icon={<Face5 />}
                    path="oppilaat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserList
                                disableSyncWithLocation
                                storeKey="schoolPupilList"
                                resource="pupil"
                                filter={{
                                    status: '0',
                                    usergroup_status: '0',
                                    sessions: true,
                                }}
                                customFilters={['parent_group']}
                                showExtra={['search']}
                                hideFields={[
                                    'loginteacher',
                                    'subjects',
                                    'role',
                                    'loginstudent',
                                    'email',
                                ]}
                                sort={{
                                    field: 'public.lastname',
                                    order: 'ASC',
                                }}
                                bulkActionButtons={false}
                                emptyIcon={user.iconPupil}
                            >
                                <span />
                            </CustomUserList>
                        </Grid>
                    </Grid>
                </FormTab>

                {/* 
                    - Tähän opiskelijat.
                    - Sivutettu sortattava listaus.
                    - Haku???
                */}
                <FormTab
                    label="resources.school.tabs.students"
                    icon={<Face />}
                    path="opiskelijat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserList
                                disableSyncWithLocation
                                storeKey="schoolStudentList"
                                resource="student"
                                customFilters={['group']}
                                showExtra={['search']}
                                filter={{
                                    status: '0',
                                    usergroup_status: '0',
                                    group_status: '0',
                                }}
                                hideFields={[
                                    'code',
                                    'middlename',
                                    'subjects',
                                    'role',
                                    'loginteacher',
                                ]}
                                sort={{
                                    field: 'public.lastname',
                                    order: 'ASC',
                                }}
                                bulkActionButtons={false}
                                emptyIcon={user.iconStudent}
                            >
                                <span />
                            </CustomUserList>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.school.tabs.stats"
                    icon={<BarChartIcon />}
                    path="kaytto_historia"
                >
                    <Grid container spacing={2}>
                        <StatisticsTile permissions={[]} />
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
};

export default SchoolEdit;
