import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import {
    useTranslate,
    useEditContext,
    useResourceContext,
    useNotify,
    useRefresh,
} from 'react-admin';

const GdprDelete = () => {
    const translate = useTranslate();
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const { record: profile } = useEditContext();
    const [open, setOpen] = useState(false);

    const handleGdprDelete = () => {
        // Handle the GDPR delete action here
        fetch(
            `${process.env.REACT_APP_INFRA_API}/${resource}/${profile.guid}?gdpr=true`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            }
        )
            .then(response => {
                if (response.status !== 200) {
                    notify(translate('resources.student.actions.gdpr.error'), {
                        type: 'error',
                    });
                    return;
                }
                notify(translate('resources.student.actions.gdpr.success'), {
                    type: 'success',
                });
                window.location.href = '/#/student';
                refresh();
            })
            .catch(() => {
                notify(translate('resources.student.actions.gdpr.error'), {
                    type: 'error',
                });
            });
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleContinue = () => {
        // Handle the continue action here
        handleGdprDelete();
        handleCloseDialog();
    };

    if (!profile || profile.status !== 0) return null;

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: 'red', marginBottom: 2 }}
                onClick={handleOpenDialog}
            >
                {translate('resources.student.actions.gdpr.button_title')}
            </Button>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                <DialogTitle id="alert-dialog-title">
                    {translate('resources.student.actions.gdpr.dialog.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate(
                            'resources.student.actions.gdpr.dialog.content',
                            {
                                name: `${profile?.public?.firstname} ${profile?.public?.lastname}`,
                                email: `${profile?.public?.email}`,
                            }
                        )}
                    </DialogContentText>
                    <DialogContentText sx={{ marginTop: 2 }}>
                        <Typography variant="body2">
                            {translate(
                                'resources.student.actions.gdpr.dialog.describe_service',
                                {
                                    service: `Cloubille`,
                                    id: `${profile?.guid}`,
                                }
                            )}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText sx={{ marginTop: 0 }}>
                        <Typography variant="body2">
                            {translate(
                                'resources.student.actions.gdpr.dialog.describe_service',
                                {
                                    service: `Kirjavälitykselle`,
                                    id: `${profile?.username}`,
                                }
                            )}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText sx={{ marginTop: 2 }}>
                        <Typography variant="body1">
                            {translate(
                                'resources.student.actions.gdpr.dialog.extra_content'
                            )}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        color="primary"
                        variant="outlined"
                        autoFocus
                    >
                        {translate(
                            'resources.student.actions.gdpr.dialog.cancel'
                        )}
                    </Button>
                    <Button
                        onClick={handleContinue}
                        color="error"
                        variant="contained"
                    >
                        {translate(
                            'resources.student.actions.gdpr.dialog.confirm'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default GdprDelete;
