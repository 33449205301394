import * as React from 'react';
import { useRecordContext } from 'react-admin';

interface Props {
    source?: string;
    label?: string;
    sortable?: boolean;
    school_guid?: string;
}

const RoleNameField = ({
    source = 'group',
    label = '',
    sortable = false,
    school_guid,
}: Props) => {
    const record = useRecordContext();
    if (!record) return null;
    let role = school_guid
        ? record?.schools?.find(s => s.guid === school_guid)?.role || ''
        : record?.schools?.[0]?.role ?? '';
    let isAdmin = school_guid
        ? record?.schools?.find(s => s.guid === school_guid)?.isAdmin || false
        : record?.schools?.[0]?.public?.isadmin ?? false;

    return (
        <div>
            {role}
            {isAdmin ? ', Ylläpitäjä' : ''}
        </div>
    );
};

export default RoleNameField;
