import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';
import { useRecordContext, useResourceContext } from 'react-admin';
import { Grid, Box, Typography, Stack, Link } from '@mui/material';
import { useTranslate } from 'react-admin';
const ConditionalMergeField = ({ ...rest }) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    if (!record) return null;

    return (
        <>
            {(record?.public['infra:merged']?.length ?? 0) > 0 && (
                <Stack direction="column" spacing={0}>
                    <Typography variant="body2">
                        {translate('merge.profilepage.description')}
                    </Typography>
                    <ArrayInput
                        resource={resource}
                        source="public.infra:merged"
                        label=""
                    >
                        <SimpleFormIterator
                            disableReordering
                            disableAdd
                            disableClear
                            disableRemove={!rest.showRemove}
                            sx={{
                                width: '100%',
                            }}
                        >
                            <TextInput
                                source=""
                                hiddenLabel
                                readOnly
                                helperText={false}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        '&::before': {
                                            borderBottom: 'none',
                                        },
                                    },
                                }}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </Stack>
            )}
        </>
    );
};

export default ConditionalMergeField;
