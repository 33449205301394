import React, { useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    useTranslate,
    useRecordContext,
    useNotify,
    useRefresh,
} from 'react-admin';

const ChangeTeacherStatus = ({ ...props }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const handleRefresh = () => {
        refresh();
    };
    const record = useRecordContext();
    const [processingButtons, setProcessingButtons] = useState({});
    const [open, setOpen] = useState(false);
    const [sendmail, setSendMail] = useState(true);
    const [language, setLanguage] = useState('fi');
    const [checkedState, setCheckedState] = useState(
        record?.schools
            ?.filter(s => s.status === 2)
            .reduce((acc, school) => {
                acc[school.guid] = true; // Default to checked
                return acc;
            }, {})
    );

    const { button: showButton = true } = props;

    const handleLanguageChange = event => {
        setLanguage(event.target.value);
    };

    const handleCheckboxChange = event => {
        const { name, checked } = event.target;
        setCheckedState(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleSendMailCheckboxChange = event => {
        const { checked } = event.target;
        setSendMail(checked);
        console.log('Send mail checked:', checked);
    };

    const handleApproveClick = (e, guid) => {
        e.stopPropagation();
        setProcessingButtons(prevState => ({ ...prevState, [guid]: true }));

        //Find the checked schools
        let checkedSchools = [];
        for (const [key, value] of Object.entries(checkedState)) {
            if (value) {
                checkedSchools.push(key);
            }
        }

        //Call api to approve teacher and assign schools
        const data = {
            action: 'APPROVE_TEACHER',
            user_guid: guid,
            groups_to_approve: checkedSchools,
            sendmail: sendmail,
            lang: language,
        };
        console.log(data);

        fetch(`${process.env.REACT_APP_INFRA_API}/teacher/${guid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                notify(translate('resources.teacher.actions.approve.success'), {
                    type: 'success',
                });
                handleRefresh();
                setProcessingButtons(prevState => ({
                    ...prevState,
                    [guid]: false,
                }));
            })
            .catch(() => {
                notify(translate('resources.teacher.actions.approve.error'), {
                    type: 'error',
                });
                setProcessingButtons(prevState => ({
                    ...prevState,
                    [guid]: false,
                }));
            });
    };

    const handleOpenDialog = e => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleCloseDialog = e => {
        e.stopPropagation();
        setOpen(false);
    };

    const handleContinue = e => {
        handleApproveClick(e, record.guid);
        handleCloseDialog(e);
    };

    if (!record) {
        return null;
    }

    return (
        <>
            {showButton ? (
                <Button
                    key={`approve_${record.guid}`}
                    variant="contained"
                    size="small"
                    color="error"
                    title={props.title}
                    startIcon={
                        processingButtons[record.guid] ? (
                            <CircularProgress size={14} />
                        ) : null
                    }
                    onClick={handleOpenDialog}
                    disabled={processingButtons[record.guid]}
                >
                    {props.title ||
                        translate(
                            'resources.teacher.actions.approve.button_title'
                        )}
                </Button>
            ) : (
                (record?.schools?.filter(s => s.status === 2)?.length ?? 0) >
                    0 && (
                    <IconButton
                        key={`approve_${record.guid}`}
                        size="small"
                        title={
                            props.title ||
                            translate(
                                'resources.teacher.actions.approve.button_title'
                            )
                        }
                        onClick={handleOpenDialog}
                    >
                        <CheckCircleIcon color="error" fontSize="inherit" />
                    </IconButton>
                )
            )}
            <Dialog
                onClick={e => e.stopPropagation()}
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
                sx={{ '& .MuiDialog-paper': { padding: 2 } }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{ backgroundColor: 'primary.main', color: 'white' }}
                >
                    {translate(
                        'resources.teacher.actions.approve.dialog.title',
                        {
                            mode:
                                record.status === 2 ? 'tunnus' : 'koululiitos',
                        }
                    )}
                </DialogTitle>
                <DialogContent>
                    {record.status === 2 && (
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{ marginTop: 2 }}
                        >
                            {translate(
                                'resources.teacher.actions.approve.dialog.content',
                                {
                                    name: `${record?.public.firstname} ${record?.public.lastname}`,
                                    username: `${record?.username}`,
                                }
                            )}
                        </DialogContentText>
                    )}
                    {(record?.schools?.filter(s => s.status === 2)?.length ??
                        0) > 0 && (
                        <DialogContentText sx={{ marginTop: 2 }}>
                            {translate(
                                'resources.teacher.actions.approve.dialog.schools'
                            )}
                        </DialogContentText>
                    )}
                    {record?.schools?.map(school => (
                        <DialogContentText key={school.guid}>
                            {school.status === 2 && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedState[school.guid]}
                                            onChange={handleCheckboxChange}
                                            name={school.guid}
                                            color="primary"
                                        />
                                    }
                                    label={school.name}
                                    key={school.guid}
                                />
                            )}
                        </DialogContentText>
                    ))}
                </DialogContent>
                <DialogActions>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={sendmail}
                                onChange={handleSendMailCheckboxChange}
                                name="sendmail"
                                color="primary"
                            />
                        }
                        label={translate(
                            'resources.teacher.actions.approve.dialog.sendmail'
                        )}
                    />

                    <FormControl sx={{ width: 120 }}>
                        <InputLabel id="language-select-label">
                            {translate(
                                'resources.teacher.actions.approve.dialog.language'
                            )}
                        </InputLabel>
                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            disabled={!sendmail}
                            value={language}
                            label={translate(
                                'resources.teacher.actions.approve.dialog.language'
                            )}
                            onChange={handleLanguageChange}
                        >
                            <MenuItem value="fi">Suomi</MenuItem>
                            <MenuItem value="sv">Ruotsi</MenuItem>
                            <MenuItem value="en">Englanti</MenuItem>
                        </Select>
                    </FormControl>

                    <Button
                        onClick={handleCloseDialog}
                        color="primary"
                        variant="outlined"
                        autoFocus
                    >
                        {translate(
                            'resources.teacher.actions.approve.dialog.cancel'
                        )}
                    </Button>
                    <Button
                        onClick={handleContinue}
                        color="primary"
                        variant="contained"
                    >
                        {translate(
                            'resources.teacher.actions.approve.dialog.confirm'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ChangeTeacherStatus;
