import * as React from 'react';
import { Link, FunctionField, useTranslate } from 'react-admin';
import { Stack, Typography } from '@mui/material';
import ChangeTeacherStatus from './ChangeTeacherStatus';

const SchoolNameField = props => {
    const translate = useTranslate();
    return (
        <FunctionField
            {...props}
            render={record => {
                return (
                    <Stack direction="row" spacing={1}>
                        <Stack direction="column" spacing={0}>
                            {record?.schools?.map(school => {
                                return (
                                    <Link
                                        key={school.guid}
                                        title={translate(
                                            `resources.user.status.${school.status}`
                                        )}
                                        sx={{
                                            textDecoration: 'none',
                                            color:
                                                school.status === 0
                                                    ? '#000'
                                                    : 'red',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                        to={'/school/' + school.guid}
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <Typography
                                            variant="body2"
                                            display="flex"
                                            flexWrap="nowrap"
                                            alignItems="center"
                                            component="div"
                                        >
                                            {school.name}
                                        </Typography>
                                    </Link>
                                );
                            })}
                        </Stack>
                        <Stack direction="row" spacing={0} alignItems="center">
                            {props.approval === 'true' &&
                                (record.status === 2 ||
                                    record.schools.filter(s => s.status === 2)
                                        .length > 0) && (
                                    <ChangeTeacherStatus
                                        key="change-teacher-status"
                                        record={record}
                                        button={false}
                                        title={translate(
                                            'resources.teacher.actions.approve.button_title'
                                        )}
                                    />
                                )}
                        </Stack>
                    </Stack>
                );
            }}
        />
    );
};

export default SchoolNameField;
